import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay } from "swiper/modules";
import logo from "../../Assets/images/logo/logo.png";
import test from "../../Assets/images/testimonial/testimonial-1.jpeg"
import { useApi } from "../../Context/ApiProvider";

const Testimonial = () => {
  const [testimonial, setTestimonial] = useState([]);
  const api = useApi();

  useEffect(() => {
    const fetchTestimonialData = async () => {
      try {
        const response = await api.get(`Review/GetReviewList`); // Update API URL
        setTestimonial(response.body.data);
      } catch (error) {
        console.error("Error fetching feature:", error);
      }
    };

    const handleFetchData = () => {
      fetchTestimonialData();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [api]);

  return (
    <section className="testimonial-area section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-7">
            <div className="section-title mx-430 mx-auto text-center">
              <span className="highlights">Testimonial</span>
              <h4 className="title">What People Have Said About Our Service</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-wrap gap-24">
        <div className="swiper bulletLeftSwiper-active">
          <Swiper
            allowTouchMove={true}
            slidesPerView={1}
            speed={6000}
            loop={true}
            autoplay={{
              delay: 0,
              disableOnInteraction: true,
              reverseDirection: true,
              pauseOnMouseEnter: true,
            }}
            spaceBetween={24}
            breakpoints={{
              1400: {
                slidesPerView: 5,
              },
              992: {
                slidesPerView: 3,
              },
              576: {
                slidesPerView: 2,
              },
            }}
            modules={[Autoplay]}
            className="about-slider"
          >
            {testimonial.map((item) => (
              <SwiperSlide className="testimonial-card">
                <div className="testimonial-header">
                  <div className="user-img">
                    <img
                      src={test}
                      alt="Routes and Tours"
                    />
                  </div>
                  <div className="user-info">
                    <p className="name">{item.customerName}</p>
                    <p className="designation">Traveler</p>
                  </div>
                </div>
                <div className="testimonial-body">
                  <p className="pera">
                    {item.comment}
                  </p>
                </div>
                <div className="testimonial-footer">
                  <div className="logo">
                    <img src={logo} alt="Routes and Tours" className="changeLogo" />
                  </div>
                  <p className="date">{new Date(item.reviewDate).toLocaleDateString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                  })}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="swiper bulletRightSwiper-active">
          <Swiper
            allowTouchMove={true}
            slidesPerView={1}
            speed={6000}
            loop={true}
            autoplay={{
              delay: 0,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            spaceBetween={24}
            breakpoints={{
              1400: {
                slidesPerView: 5,
              },
              992: {
                slidesPerView: 3,
              },
              576: {
                slidesPerView: 2,
              },
            }}
            modules={[Autoplay]}
            className="about-slider"
          >
            {testimonial.map((item) => (
              <SwiperSlide className="testimonial-card">
                <div className="testimonial-header">
                  <div className="user-img">
                    <img
                      src={test}
                      alt="Routes and Tours"
                    />
                  </div>
                  <div className="user-info">
                    <p className="name">{item.customerName}</p>
                    <p className="designation">Traveler</p>
                  </div>
                </div>
                <div className="testimonial-body">
                  <p className="pera">
                    {item.comment}
                  </p>
                </div>
                <div className="testimonial-footer">
                  <div className="logo">
                    <img src={logo} alt="Routes and Tours" className="changeLogo" />
                  </div>
                  <p className="date">{new Date(item.reviewDate).toLocaleDateString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                  })}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div> 
    </section>
  );
};

export default Testimonial;
