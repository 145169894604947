import React, { useCallback, useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../../Common/Breadcrumbs";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import SidebarFilter from "./SidebarFilter";
import TopBar from "./TopBar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useApi } from "../../../Context/ApiProvider";
import { Image_URL } from "../../../Common/BaseURL";
import ReactPaginate from "react-paginate";

const TourList = ({ toggleTheme }) => {
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [cityMap, setCityMap] = useState({});
  const [tourTypeMap, setTourTypeMap] = useState({});
  const [filters, setFilters] = useState({
    search: "",
    tourtype: "",
    city: "",
    PriceStart: "",
    PriceEnd: "",
    startDate: "",
    endDate: "",
    orderby: "",
    ordertype: "",
    minday: "",
    maxday: "",
    rating: ""
  });
  const filterLabels = {
    search: "Search",
    tourtype: "Tour Type",
    city: "Destination",
    PriceStart: "Price Start",
    PriceEnd: "Price End",
    startDate: "Start Date",
    endDate: "End Date",
    orderby: "Order By",
    ordertype: "Order Type",
    minday: "Min Days",
    maxday: "Max Days",
    rating: "Rating"
  };
  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPageRef = useRef(page);
  const currentFiltersRef = useRef(filters);

  const resultsPerPage = 12;

  const fetchFiltersData = useCallback(async () => {
    try {
      const [destinationResponse, tourTypeResponse] = await Promise.all([
        api.get("Data/GetDestination"),
        api.get("TourType/TourTypeList"),
      ]);

      const cityMap = {};
      destinationResponse.body.data.forEach((city) => {
        cityMap[city.cityName] = `${city.cityName}, ${city.stateName}`;
      });
      setCityMap(cityMap);

      const tourTypeMap = {};
      tourTypeResponse.body.data.forEach((tour) => {
        tourTypeMap[tour.tourTypeName] = tour.tourTypeName;
      });
      setTourTypeMap(tourTypeMap);
    } catch (err) {
      console.error("Error fetching filter data", err);
    }
  }, [api]);

  useEffect(() => {
    const handleFetchData = () => {
      fetchFiltersData();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [fetchFiltersData]);

  const fetchTours = useCallback(
    async (currentPage) => {
      currentPageRef.current = currentPage;
      currentFiltersRef.current = filters;

      setLoadingPage(true);

      try {
        const params = {
          message: currentPage,
          recordStart: (currentPage - 1) * resultsPerPage,
        };

        if (filters.search) params.search = filters.search;
        if (filters.tourtype) params.tourtype = filters.tourtype;
        if (filters.city) params.city = filters.city;
        if (filters.PriceStart) params.PriceStart = filters.PriceStart;
        if (filters.PriceEnd) params.PriceEnd = filters.PriceEnd;
        if (filters.startDate) params.startDate = filters.startDate;
        if (filters.endDate) params.endDate = filters.endDate;
        if (filters.orderby) params.orderby = filters.orderby;
        if (filters.ordertype) params.ordertype = filters.ordertype;
        if (filters.minday) params.minday = filters.minday;
        if (filters.maxday) params.maxday = filters.maxday;
        // Add the rating to the params if it exists
        if (filters.rating) {
          params.rating = filters.rating; // Assuming it's a single value
        }

        const response = await api.get("Data/GetPackages", params);
        const { data, recordsFiltered } = response.body;

        if (currentPageRef.current === currentPage) {
          setTours(data);
          setTotalRecords(recordsFiltered);
          setTotalPages(Math.ceil(recordsFiltered / resultsPerPage));
        }
      } catch (err) {
        console.error("Error fetching tour data", err);
      } finally {
        setLoadingPage(false);
        setLoading(false);
      }
    },
    [api, resultsPerPage, filters]
  );

  useEffect(() => {
    const handleFetchData = () => {
      fetchTours(page);
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [filters, page, fetchTours]);

  const handlePageChange = (selectedPage) => {
    const newPage = selectedPage.selected + 1;
    setPage(newPage);

    const queryParams = new URLSearchParams({
      ...filters,
      page: newPage,
    }).toString();

    navigate(`/TourList?${queryParams}`);
    window.scrollTo(0, 0);
  };

  const updateFilters = (newFilters) => {
    const updatedFilters = {
      ...filters,
      ...newFilters,
      // search: Object.keys(newFilters).some((key) => key !== "search") ? "" : filters.search,
    };
    setFilters(updatedFilters);

    const queryParams = new URLSearchParams({
      ...updatedFilters,
      page: 1,
    }).toString();

    navigate(`/TourList?${queryParams}`);
    setPage(1); // Reset page to 1 when filters are applied
  };

  const handleSortChange = (event) => {
    const value = event.target.value;
    let orderby, ordertype;

    switch (value) {
      case "popular":
        orderby = "popular";
        ordertype = "asc";
        break;
      case "new":
        orderby = "new";
        ordertype = "asc";
        break;
      case "low":
        orderby = "price";
        ordertype = "asc";
        break;
      case "high":
        orderby = "price";
        ordertype = "desc";
        break;
      default:
        orderby = "";
        ordertype = "";
    }

    updateFilters({ orderby, ordertype });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const queryFilters = {
      search: queryParams.get("search") || "",
      tourtype: queryParams.get("tourtype") || "",
      city: queryParams.get("city") || "",
      PriceStart: queryParams.get("PriceStart") || "",
      PriceEnd: queryParams.get("PriceEnd") || "",
      startDate: queryParams.get("startDate") || "",
      endDate: queryParams.get("endDate") || "",
      orderby: queryParams.get("orderby") || "",
      ordertype: queryParams.get("ordertype") || "",
      minday: queryParams.get("minday") || "",
      maxday: queryParams.get("maxday") || "",
      rating: queryParams.get("rating") || "",
    };

    setFilters(queryFilters);
    setPage(Number(queryParams.get("page")) || 1);
  }, [location.search]);

  const handleFilterChange = (type, value) => {
    updateFilters({ [type]: value });
  };

  const handleApplyFilters = (newFilters) => {
    updateFilters(newFilters);
    setPage(1); // Reset page to 1 when filters are applied
  };

  const handleClearFilter = (type) => {
    updateFilters({ [type]: "" });
  };

  const handleRatingChange = (rating) => {
    // Update filters with the new rating value
    updateFilters({ rating: rating }); // Assuming rating is a single value now
  };

  const handleClearAllFilters = () => {
    updateFilters({
      search: "",
      tourtype: "",
      city: "",
      PriceStart: "",
      PriceEnd: "",
      startDate: "",
      endDate: "",
      orderby: "",
      ordertype: "",
      minday: "",
      maxday: "",
      rating: ""
    });
  };

  const startResult = (page - 1) * resultsPerPage + 1;
  const endResult = Math.min(page * resultsPerPage, totalRecords);
  const cityName = cityMap[filters.city] || filters.city;
  const tourTypeName = tourTypeMap[filters.tourtype] || filters.tourtype;

  if (loading && page === 1) {
    return (
      <div className="loading">
        <h2 className="highlights fancy-font font-400">Routes and Tours</h2>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  }

  return (
    <div>
      <Header toggleTheme={toggleTheme} />
      <Breadcrumbs heading="Tour List" link="/TourList" />

      <section className="tour-list-section section-padding2">
        <div className="container">
          {Object.keys(filters).some((key) => filters[key] !== "") && (
            <div>
              <div className="d-flex align-items-center justify-content-between mb-20">
                <div>
                  <h4 className="title">Applied Filters</h4>
                </div>
                <div>
                  <button
                    className="btn-primary-icon-sm-filter"
                    onClick={handleClearAllFilters}
                  >
                    Clear All
                  </button>
                </div>
              </div>
              <div className="row pb-20 g-2">
                {Object.keys(filters).map((key) =>
                  filters[key] ? (
                    <div className="col-lg-2 col-md-3 col-sm-4" key={key}>
                      <div className="applied-filters d-flex align-items-center justify-content-between">
                        <span className="filter-text">
                          <p className="font-bold">{filterLabels[key]}:</p>
                          {key === "city"
                            ? cityName
                            : key === "tourtype"
                              ? tourTypeName
                              : filters[key]}
                        </span>
                        <button
                          className="clear-filters ms-2"
                          aria-label={`Clear ${key} filter`}
                          onClick={() => handleClearFilter(key)}
                        >
                          <i className="ri-close-line"></i>
                        </button>
                      </div>
                    </div>
                  ) : null
                )}
              </div>
            </div>
          )}
          <div className="row g-4">
            <div className="col-xl-3">
              <SidebarFilter
                onTourTypeChange={(tourType) =>
                  handleFilterChange("tourtype", tourType)
                }
                onCityChange={(city) => handleFilterChange("city", city)}
                onApplyFilters={handleApplyFilters}
                handleRatingChange={handleRatingChange}
                initialFilters={filters}
                cityMap={cityMap}
                tourTypeMap={tourTypeMap}
              />
            </div>
            <div className="col-xl-9">
              <TopBar
                totalResults={totalRecords}
                startResult={startResult}
                endResult={endResult}
                onSortChange={handleSortChange}
              />
              {loadingPage && (
                <div className="loading">
                  <h2 className="highlights fancy-font font-400">
                    Routes and Tours
                  </h2>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              )}
              <div className="all-tour-list">
                <div className="row g-4">
                  {tours?.length === 0 ? (
                    <div className="no-record">No Record found!</div>
                  ) : (
                    !loadingPage &&
                    tours &&
                    tours.map((tour) => (
                      <div
                        key={tour.packageId}
                        className="col-xl-4 col-lg-4 col-sm-6"
                      >
                        <div className="package-card">
                          <div className="package-img imgEffect4">
                            <Link to={`/TourDetail/${tour.packageName}`}>
                              <img
                                src={
                                  tour.imagename
                                    ? `${Image_URL}/Package/${tour.imagename}`
                                    : "assets/images/package/package-4.png"
                                }
                                alt={tour.packageName}
                              />
                            </Link>
                          </div>
                          <div className="package-content">
                            <h4 className="area-name">
                              <Link to={`/TourDetail/${tour.packageName}`}>
                                {tour.packageName}
                              </Link>
                            </h4>
                            <div className="location">
                              <i className="ri-map-pin-line"></i>
                              <div className="name">
                                {tour.stateName}
                              </div>
                            </div>
                            <div className="packages-person">
                              <div className="count">
                                <i className="ri-time-line"></i>
                                <p className="pera">
                                  {tour.days} Days {tour.nights} Nights
                                </p>
                              </div>
                              <div className="count">
                                <i className="ri-user-line"></i>
                                <p className="pera">2 Person</p>
                              </div>
                            </div>
                            <div className="price-review">
                              <div className="d-flex gap-10">
                                <p className="light-pera">From</p>
                                <p className="pera">₹{tour.priceForDouble}</p>                                
                              </div>
                              <div>Per person on twin sharing</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>

                {/* React Paginate */}
                {totalRecords > resultsPerPage && (
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={"re-pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                    forcePage={page - 1}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default TourList;
